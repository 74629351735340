@font-face {
  font-family: "chunkfive";
  src: url("/css/fonts/chunkfive-webfont.eot");
  src: url("/css/fonts/chunkfive-webfont.eot?#iefix") format("embedded-opentype"),
    url("/css/fonts/chunkfive-webfont.woff") format("woff"),
    url("/css/fonts/chunkfive-webfont.ttf") format("truetype"),
    url("/css/fonts/chunkfive-webfont.svg#chunkfiveroman") format("svg");
  font-weight: normal;
  font-style: normal;
}

$sans-serif: Helvetica, Arial, sans-serif;
$chunkfive: "chunkfive", Impact, $sans-serif;
$raleway: "Raleway", $sans-serif;

// Type scale
// -------------------------
// http://www.modularscale.com/?17&px&1.067?19&px&1.067
//
// 1. Base font size is dependent on breakpoint
//    1rem = 17px (mobile, desktop) OR 19px (tablet, desktop HD)
// 2. $type-scale: the base scale unit for sizing fonts
// 3. All fonts sizes are declared in rem
// 4. One font-size declaration, but different computed size depending on breakpoint

$type-scale: 1.067;
$text-base-size: 1rem;



// Return a number raised to a specified power
@function pow($number, $exponent: 0) {
  $value: 1;

  @if (type-of($number) != number) {
    @error "`#{$number}` needs to be a number.";
  }

  @for $i from 1 through $exponent {
    $value: $value * $number;
  }

  @return $value;
}

// Make font bigger
$text-grow-1: $text-base-size * pow($type-scale, 1); // 20.273px
$text-grow-2: $text-base-size * pow($type-scale, 2); // 21.631px
$text-grow-3: $text-base-size * pow($type-scale, 3); // 23.081px
$text-grow-4: $text-base-size * pow($type-scale, 4); // 24.627px
$text-grow-5: $text-base-size * pow($type-scale, 5); // 26.277px
$text-grow-6: $text-base-size * pow($type-scale, 6); // 28.038px
$text-grow-7: $text-base-size * pow($type-scale, 7); // 29.916px
$text-grow-8: $text-base-size * pow($type-scale, 8); // 31.92px
$text-grow-9: $text-base-size * pow($type-scale, 9); // 34.059px
$text-grow-10: $text-base-size * pow($type-scale, 10); // 36.341px
$text-grow-11: $text-base-size * pow($type-scale, 11); // 38.776px
$text-grow-12: $text-base-size * pow($type-scale, 12); // 41.374px

// Make font smaller
$text-shrink-1: $text-base-size / pow($type-scale, 1); // 17.807px
$text-shrink-2: $text-base-size / pow($type-scale, 2); // 16.689px
$text-shrink-3: $text-base-size / pow($type-scale, 3); // 15.641px
$text-shrink-4: $text-base-size / pow($type-scale, 4); // 14.659px
$text-shrink-5: $text-base-size / pow($type-scale, 5); // 13.738px
$text-shrink-6: $text-base-size / pow($type-scale, 6); // 12.876px

// Line heights
$line-height-base: 1.5;
$line-height-sm: 1.2;
$line-height-xs: 1;
