@use "../../css/abstracts/" as *;

.hero {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  color: white;

  &:before {
    content: '';
    display: block;
    background: $teal;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    transform: translateX(-100%);
    animation: bg 0.6s ease-in-out forwards;
    animation-delay: 1.5s;
  }
}

@keyframes bg {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.content-layer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 2;
} // close masthead

.logo-animate {
  fill: #fff;
  stroke-dasharray: 1200;
  animation: drawlogo 3s ease-out forwards;
}

@keyframes drawlogo {
  0% {
    stroke-dashoffset: 1300;
    stroke: $dark-gray;
  }
  20% {
    stroke-dashoffset: 50;
    fill: #ccc;
  }
  50% {
    stroke-dashoffset: 50;
    stroke: transparent;
  }
  80% {
    stroke-dashoffset: 0;
    fill: $dark-gray;
  }
}

.logo-icon-animate {
  fill: #fff;
  width: 30vh;
  height: 30vh;
  animation: logomove 3s ease-out forwards;

  @keyframes logomove {
    90% {
      width: 30vh;
      height: 30vh;
      opacity: 1;
    }
    100% {
      width: 50px;
      height: 50px;
      opacity: 0;
      display: none;
    }
  } 

  @media (min-width: $bp_lg) {
    width: 40vh;
    height: 40vh;

    @keyframes logomove {
      90% {
        width: 40vh;
        height: 40vh;
        opacity: 1;
      }
      100% {
        width: 50px;
        height: 50px;
        opacity: 0;
        display: none;
      }
    } 
  }

  @media (min-width: $bp_lg) {
    width: 50vh;
    height: 50vh;

    @keyframes logomove {
      90% {
        width: 50vh;
        height: 50vh;
        opacity: 1;
      }
      100% {
        width: 50px;
        height: 50px;
        opacity: 0;
        display: none;
      }
    } 
  }
}

.blurb {
  animation: showBlurb 0.6s ease-in forwards;
  animation-delay: 3.2s;
  max-width: 700px;
  color: white;

  .name {
    font-family: $chunkfive;
    font-size: 3rem;
    margin: 0;
    @media (min-width: $bp-md) {
      font-size: 5rem;
    }
  }

  .dev {
    font-family: $chunkfive;
    font-size: $text-grow-7;
    margin: 0 0 1.5rem;

    @media (min-width: $bp-md) {
      font-size: $text-grow-12;
    }
  }

  .jobtitle {
    display: inline-flex;
    position: relative;
    color: $dark-gray;
    overflow: hidden;
    flex-direction: column;
    height: 35px;
    width: fit-content;

    @media (min-width: $bp-md) {
      height: 45px;
    }

    span {
      animation: spin_words 8s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
      padding-bottom: 20px;
      height: 100%;
      animation-delay: 3.2s;
    }
  }
 
  .lead {
    font-size: $text-grow-4;
    margin: 0 0 1.5rem;
    line-height: $line-height-base;

    @media (min-width: $bp-md) {
      font-size: $text-grow-6;
      line-height: $line-height-sm;
    }
  }
}

@keyframes spin_words{
  10%{
      transform: translateY(-112%);
  }
  25%{
      transform: translateY(-100%);
  }
  35%{
      transform: translateY(-212%);
  }
  50%{
      transform: translateY(-200%);
  }
  60%{
      transform: translateY(-312%);
  }
  75%{
      transform: translateY(-300%);
  }
  85%{
      transform: translateY(-412%);
  }
  100%{
      transform: translateY(-400%);
  }
}
