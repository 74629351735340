@use "../../css/abstracts/" as *;

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  
  z-index: 10;
  top: 0px;
  width: 100%;
  background: hsla(0,0%,7%,.25);
  padding: 0 1.5rem;
  backdrop-filter: blur(12px);
  transform: translateY(-100%);
  animation: navstart 0.6s ease-in-out forwards;
  //animation-delay: 3.2s;

  @media (min-width: $bp-md) {
    padding: 0 3rem;
  }
  
}

@keyframes navstart {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.social {
  display: flex;
  li {
    list-style: none;

    a {
      display: inline-block;
      margin: 0 .5rem;
      color: $dark-gray;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}