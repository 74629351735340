$teal: #2dc3c3;
$blue: #00cee4;
$yellow: #f2b503;
$green: #5eb991;
$dark-gray: #333;
$light-green: #6caf13;
$orange: #e15b29;
$yellow-green: #e0e28b;
$orang2: #ee8e5d;
$purple: #a81f6e;

$transition: all 0.3s ease 0s;

// Breakpoints
// -------------------------
$bp-sm: 320px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1440px;

// Side Spacing
// -------------------------
$outer-sm: 15px;
$outer-md: 30px;
$outer-lg: 30px;
$outer-xl: 35px;
