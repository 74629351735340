@mixin hi-res() {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (-moz-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 192dpi) {
    @content;
  }
}

// CLEARFIX
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

// VISIBILITY
.hidden-content {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin show-on-focus {
  @include hidden-content;

  &:focus {
    width: auto;
    height: auto;
    clip: auto;
  }
}

.white-link {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
  font-weight: bold;
  &:hover {
    color: black;
    border-color: black;
  }
}

.mobile-hide {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.reveal {
  position: relative;
  width: fit-content;
  overflow: hidden;

  .band {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    right: 0;
    background:#333;
    z-index: 20;
  }
}