@use "../../css/abstracts/" as *;

.grid--omake {
  margin: 0;
  padding: 0;
  list-style: none;

  @media (min-width: $bp_md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: $bp_lg) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 45px;
  }
}

.item {
  display: flex;

  @media (max-width: calc($bp_md - 1px)) {
    align-items: center;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: $green;
    margin-top: 5px;
  }
}




.summary {
  margin: 0 0 0 15px;
  line-height: $line-height-base;
}
