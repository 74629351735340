@use "../../css/abstracts/" as *;

.intro {
  background-color: $yellow;
  color: #fff;
  border-top: 2px solid #fff;
  //margin-top: calc(2*18px + 50px) * -1;
}

.box {
  display: flex;
}

.desc {
  margin-left: 15px;
}

.title {
  font-size: $text-grow-1;
  text-transform: uppercase;
}

.icon {
  fill: white;
  @media (max-width: $bp_md) {
    width: 50px;
  }
}


.summaries {
  @media (min-width: $bp_md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $bp_lg) {
    grid-template-columns: repeat(4, 1fr);
  }
}
