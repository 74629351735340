@use "../../css/abstracts/" as *;

$grid-color: #c9c9c9;
@mixin grid-row {
  grid-row: 1 / span 1;
}

.skills {
  background: #efefef;
  //height: 797px;
  box-shadow: inset 0px 2px 3px rgba(50, 50, 50, 0.2);
} // close skills

.skills__graph {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  border-bottom: 1px solid $grid-color;
  margin-bottom: 15px;
  position: relative;

  @media (min-width: $bp_md) {
    margin-right: 30px;
  }
} //close skill level

.skills__line {
  @include grid-row;
  display: flex;
  justify-content: flex-end;
  border-right: 1px dashed $grid-color;

  &--basic {
    grid-column: 3;
  }
  &--average {
    grid-column: 4;
  }

  &--proficient {
    grid-column: 5;
  }
} //close span

.skills__label {
  align-self: flex-end;
  margin-bottom: -42px;
  transform: rotate(45deg);

  @media (min-width: $bp_md) {
    transform: rotate(0deg);
    //margin-bottom: -30px;
    font-size: $text-grow-2;
  }
}

.bar-graph {
  grid-column: 1 / -1;
  top: 0;
  width: 100%;
  @include grid-row;
  margin-bottom: 10px;
  padding-left: 0;

}

.bar {
  background-color: $dark-gray;
  margin-bottom: 5px;
  font-size: $text-shrink-1;
  padding: 10px 15px;
  color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 30;
  white-space: nowrap;
  transition: all 2s ease-in-out;

  &:nth-child(2) {
    background-color: $green;
  }
  &:nth-child(3n + 2) {
    background-color: $green;
  }
  &:nth-child(3n) {
    background-color: $blue;
  }
} //close div
