@use "./abstracts" as *;


// Element styles
// -------------------------
body,
html {
  font-family: $raleway;
  box-sizing: border-box;
  color: #363636;
  position: relative;
  font-weight: 400;
  margin: 0;
  background-color: #f6f6f6;
  //width: 100%; /* for fullscreen on initial load */
  //height: 100%; /* for fullscreen on initial load */
}

html {
  box-sizing: border-box;
  min-height: 100%;
  overflow-x: hidden;
  text-size-adjust: 100%;
}

:root,
:root.component-selector-wrapper {
  font-size: 14px;

  @media (min-width: $bp-md) {
    font-size: 16px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  font-weight: normal;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
}



$gutter-desktop: 25px;
$gutter-tablet: 15px;

.grid {
  display: grid;
  grid-column-gap: $gutter-tablet;
  grid-row-gap: $gutter-tablet;
  margin-left: 0;
  margin-right: 0;
  grid-auto-flow: row dense;

  @media (min-width: $bp_md) {
    grid-column-gap: $gutter-desktop;
    grid-row-gap: $gutter-desktop;
  }
}

.container {
  margin: auto;
  padding: 0 20px;

  @media (min-width: $bp_lg) {
    max-width: 1000px;
  }

  @media (min-width: var(--bp-xl)) {
    max-width: 1100px;
  }

  &--testimonials {
    z-index: 2;
    position: relative;
  }
}

.section {
  padding: 3.6em 15px;
  position: relative;
}

.section__heading {
  display: flex;
  justify-content: center;
  font-family: $chunkfive;
  font-size: $text-grow-12;
  text-align: center;
  margin: 0;

  &--contact {
    font-size: $text-grow-12;
    @media (min-width: $bp_md) {
      font-size: 5rem;
    }
  }
}

.section__lead {
  font-size: $text-grow-3;
  line-height: $line-height-base;
  margin: 0 0 2.2em;
  display: flex;
  justify-content: center;
  text-align: center;

  @media (min-width: $bp_md) {
    margin: 0 116px 2.2em;
  }
}

.arrow {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  position: absolute;

  &--blue {
    border-top-color: $teal;
    top: 0;
    left: 50%;
    margin-left: -30px; /* off center arrow */
  }

  &--white {
    border-top-color: #fff;
    margin-left: -15px;
    bottom: -30px;
    left: 50%;
    z-index: 30;
  }
}

.btn {
  display: inline-block;
  position: relative;
  color: $light-green;
  background-color: transparent;
  border: 2px solid $light-green;
  font-size: $text-grow-3;
  padding: .5rem 1.3rem;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  transition: $transition;
  font-weight: bold;

  &_white {
    color: white;
    border-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    transition: 0.5s all ease;    
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    background-color: $yellow;
    z-index: -2;
  }
  &:hover {
    &:before {
      transition: 0.5s all ease;
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
  &:focus {
    &:before {
      transition: 0.5s all ease;
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
}

.btn + .btn {
  margin-left: 20px;
}




.copyright {
  background: #363636;
  border-top: 1px solid #5f5e5e;
  color: #fff;
  text-align: center;
  padding: 30px;
  font-family: $sans-serif;

  > span {
    display: inline-block;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #5f5e5e;
    font-style: normal;
  }
} //close copyright
