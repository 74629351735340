@use "../../css/abstracts/" as *;

.works-list {
  @media (min-width: $bp_md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.work {
  display: block;
  margin: auto;
  border: 1px solid #ddd;
  background-color: #555;
  cursor: pointer;
  line-height: 0;
  position: relative;
  overflow: hidden;

  @media (max-width: ($bp-md - 1)) {
    max-width: 360px;
  }

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 50, 50, 0.9);
    z-index: 2;
    transform: translateY(-100%);
    transition: $transition;
    color: white;
    font-size: $text-grow-5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover,
  &:focus {
    div {
      transform: translateY(0);
    }
  }
}
