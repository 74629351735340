@use "../../css/abstracts/" as *;

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  position: relative;
  flex-direction: column;
  animation: heroimg 0.6s ease-in forwards;
}

@keyframes heroimg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.headline {
  font-family: $chunkfive;
  font-size: 3rem;
  text-align: center;
  margin: 1em 0 0;
  color: $dark-gray;

  @media (min-width: $bp-md) {
    margin: 0; 
    font-size: 4em;
  }
}

.back-btn {
  position: absolute;
  left: 2rem;
  top: 0;
  z-index: 1;

  a {
    text-decoration: none;
    font-weight: bold;
    border-bottom: 2px solid $teal;
    color: $dark-gray;
    display: inline-block;
    padding: .2rem .0 .1rem;

    &::before {
      content: '«';
      display: inline-block;
      margin-right: .5rem;
    }

    &:hover,
    &:focus {
      border-color: $dark-gray;
    }
  }
}

.bleed {
  background-color: $orange;
  padding: 6rem 0;
  color: white;

  .grid {
    display: grid;
    @media (min-width: $bp_md) {
      grid-template-columns: 2fr 1fr;
      gap: 2rem;
    }
  }

  .title {
    font-size: $text-grow-10;
    font-family: $chunkfive;
    border-bottom: 1px solid currentColor;
    margin-bottom: 1rem;
  }

  p {
    font-size: $text-grow-4;
    line-height: $line-height-base;
    margin-bottom: 2rem;
  }
}

.techlist {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  li {
    margin: 0 0 10px;
    padding: 0;
    width: 235px;
    &:before {
      display: inline-block;
      content: "";
      width: 16px;
      height: 16px;
      background-image: url('/css/images/check.svg');
      background-size: cover;
      background-position: 0 2px;
      background-repeat: no-repeat;
      margin-right: 10px;
    }
  }
}

.work-grid {
  display: grid;
  gap: 2rem;


  @media (min-width: $bp-md) {
    grid-template-columns: repeat(2, 1fr);
  }
}